const SCRIPT_URL = process.env.REACT_APP_SESSION_RECORDER_SCRIPT_URL as string;

export const getCodeToInstall = ({ projectId }: { projectId: string }) =>
  `
<script>
  (function(w, d, s, u, a, m) {
    a = d.createElement(s), m = d.getElementsByTagName(s)[0];a.src = u;m.parentNode.insertBefore(a, m);
  })(window, document, 'script', atob('${SCRIPT_URL}').replace(':project_id', '${projectId}'));
</script>
`.trim();
